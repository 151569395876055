import Image from 'next/image';

import { minWidth } from '@hultafors/shared/style';

import { DualImageFragment, ImageFragment } from '@hultafors/eripiowear/types';

import { DoubleImageStyled, ImageContainer } from './dual-image-block.styled';

export interface DualImageBlockProps extends DualImageFragment {
  priority?: boolean;
}

export const DualImageBlock: React.FC<DualImageBlockProps> = ({
  dualImage: images,
  priority = false,
}) => {
  const imageMapper = (image: ImageFragment, index: number) => {
    const sizes = [minWidth.mobileMax`50vw`, '100vw'].join(', ');
    return (
      <ImageContainer key={`dual-image-${index}`}>
        <Image
          alt={image.alt || ''}
          src={image.responsiveImage?.src || ''}
          blurDataURL={image.responsiveImage?.base64 || ''}
          placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
          fill
          sizes={sizes}
          priority={priority}
        />
      </ImageContainer>
    );
  };
  return <DoubleImageStyled>{images.map(imageMapper)}</DoubleImageStyled>;
};
