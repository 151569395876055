import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  depth,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/eripiowear/helpers';

import { H1 } from '../h1/h1';
import { H3 } from '../h3/h3';

import HeroSvg from './hero.svg';

const ContentBlock = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.ContentBlock),
);

type HeroImageProps = Pick<HeroBlockStyledProps, '$image' | '$noGradient'>;

interface ThinProps {
  $thin?: boolean;
}

interface HeroOuterProps {
  $marginBottom?: boolean;
}

export const HeroOuter = styled(ContentBlock).attrs({
  fullBleed: true,
})<HeroOuterProps>`
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-block-end: ${spacing.regular};
    `}
`;

interface HeroBlockStyledProps {
  $image?: boolean;
  $doubleColorTitle?: boolean;
  $alignContentLeft?: boolean;
  $heroContentBoxBig?: boolean;
  $boxColor?: string;
  $heroIcon?: boolean;
  $pageType?: string;
  $noGradient?: boolean;
}

export const HeroBlockStyled = styled.div<HeroImageProps & ThinProps>`
  overflow: hidden;
  position: relative;
  height: auto;
  width: 100%;
  background: ${colors.gray5};
  background-image: url('/svg/triangle.svg');
  background-repeat: no-repeat;
  background-size: auto 125%;
  background-position: right bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1 / 1;
  max-height: ${({ $image }) => ($image ? 'unset' : 'calc(100vh - 120px)')};

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 16 / 9;
    min-block-size: auto;
    block-size: auto;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: ${({ $thin }) => ($thin ? '32 / 9' : '12 / 5')};
  }

  color: ${({ $image }) => ($image ? colors.white : colors.solidBlack)};
  border-block-end: ${({ $image }) =>
    $image ? 'none' : 'thin solid ' + colors.gray5};
  z-index: 1;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    color: ${colors.white};
  }

  ${({ $image, $noGradient }) =>
    $image &&
    !$noGradient &&
    css`
      &::before {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        inset-block-end: 0;
        inline-size: 100%;
        block-size: 70%;
        background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
        z-index: 1;
      }
    `}
`;

interface HeroInnerProps {
  $left?: boolean;
}
export const Inner = styled.div<HeroInnerProps>`
  z-index: ${depth.higher};
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${({ $left }) => ($left ? 'flex-start' : 'center')};
  justify-content: ${({ $left }) => ($left ? 'flex-end' : 'center')};
  text-align: ${({ $left }) => ($left ? 'inherit' : 'center')};
  max-width: 1440px;
  width: 100%;
  padding-inline: 20px;
  padding-block: 20px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: 40px;
  }
`;

export const GuideButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: ${colors.orange};
  border-radius: 0;
  border: 0;
  box-sizing: border-box;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  font-size: ${fontSizes.m};
  height: 48px;
  line-height: 48px;
  padding: 0 ${spacing.small};
  padding-inline: 24px;
  text-align: end;
  text-transform: uppercase;
  transition: 0.2s;
  white-space: nowrap;

  &:hover {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }

  svg {
    position: static;
    display: flex;
    margin-inline-start: 8px;
    inset-block-start: unset;
    transform: none;
    inline-size: 12px;
    block-size: 12px;
  }
`;

export const GuideButtonContainer = styled.div`
  position: absolute;
  top: -48px;
  right: 0;
`;

/* Yellow slash thing */
export const HeroIcon = styled(HeroSvg)`
  margin-block-end: 20px;
  color: ${colors.orange};
  fill: ${colors.orange};
  height: 56px;
`;

interface TitleProps {
  $withIcon?: boolean;
  $shadow?: boolean;
  $wide?: boolean;
}

export const Title = styled(H1)<TitleProps>`
  text-transform: uppercase;
  text-shadow: ${({ $shadow }) =>
    $shadow ? `0 0 1rem ${colors.black}` : 'none'};
  max-width: ${({ $wide }) => ($wide ? 'auto' : '24ch')};
  margin-block: 0 1rem;
  font-weight: ${fontWeights.normal};
  color: ${({ $shadow }) => ($shadow ? colors.white : colors.black)};
  font-size: ${fontSizes.h1Mobile};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.h1};
  }

  ${({ $withIcon }) =>
    $withIcon &&
    css`
      position: relative;
      font-size: 22px;
      line-height: 28px;
      margin-block-start: 27px;
      padding-block-start: 34px;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        inline-size: 78px;
        block-size: 96px;
        background: #f00 url('/assets/gfx/yellow-line-big.svg');
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        inset-block-start: -24px;
        inset-inline-start: -27px;

        @media screen and (min-width: ${breakpoints.mobileMax}) {
          font-size: ${fontSizes.h1};
        }
      }

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        color: ${colors.white};
        font-size: ${fontSizes.h1};
        line-height: 3.125rem;
        margin-inline-start: 48px;
        padding-block-start: 0;

        &::before {
          inset-block-start: -45px;
          inset-inline-start: -30px;
        }
      }
    `}
`;
interface DescriptionProps {
  $withLine?: boolean;
  $shadow?: boolean;
}

export const DescriptionWrapper = styled.div<DescriptionProps>`
  ${({ $withLine }) =>
    $withLine &&
    css`
      width: 100vw;
      margin-inline-start: -20px;
      margin-block-end: -20px;
      padding-inline: 20px;
      padding-block-start: 20px;
      background-color: white;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        inline-size: auto;
        margin-inline-start: unset;
        padding: unset;
        background-color: transparent;
      }
    `}
`;

export const Description = styled.p<DescriptionProps>`
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.bodyMobile};
  text-shadow: ${({ $shadow }) =>
    $shadow ? `0 0 1rem ${colors.black}` : 'none'};
  color: ${({ $shadow }) => ($shadow ? colors.white : colors.black)};
  font-weight: ${fontWeights.normal};
  max-width: 60ch;
  margin-block-start: 0;

  ${({ $withLine }) =>
    $withLine &&
    css`
      position: relative;
      margin-block-end: 0;
      color: ${colors.black};
      font-size: 16px;
      font-weight: ${fontWeights.normal};
      letter-spacing: 0.5px;
      line-height: 26px;
      margin-left: 42px;
      text-shadow: none;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        color: ${colors.white};
        font-size: 18px;
        line-height: 28px;
        margin-inline-start: 48px;
        margin-block-end: 50px;
        text-shadow: 0 0 0.333rem ${colors.black};
      }

      &::before {
        content: '';
        display: block;
        block-size: 1px;
        inline-size: 27px;
        background-color: ${colors.black};
        position: absolute;
        inset-block-start: 11px;
        inset-inline-start: -42px;

        @media screen and (min-width: ${breakpoints.mobileMax}) {
          background-color: ${colors.white};
          inset-inline-start: -48px;
        }
      }
    `}
`;

export const HeroSubtitle = styled.p<Pick<HeroBlockStyledProps, '$image'>>`
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.bodyMobile};
  color: ${({ $image }) => ($image ? colors.white : colors.black)};
  max-width: 54ch;
  margin-top: 0 !important;
  margin-bottom: 25px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    color: ${({ $image }) => ($image ? colors.white : colors.black)};
  }
`;

export const PreTitle = styled(H3)`
  font-size: 22px;
  line-height: 28px;
  margin-block: 0 -15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const ContentBoxBig = styled.div`
  position: relative;
  align-self: flex-start;
  min-height: 60px;
  background-color: white;
  padding-inline-start: 16px;
  padding-block-start: 35px;
  margin-block-start: -60px;
  z-index: 1;
  margin-inline-end: 16px;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline-start: calc(16px + 8.2vw);
    margin-inline-end: calc(16px + 8.2vw);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-inline: calc(16px + 17.25vw) 5vw;
    margin-inline-end: calc(16px + 12.25vw);
    margin-block-start: -180px;
    min-block-size: 180px;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    padding-inline: calc(16px + 25.3vw) 5vw;
    margin-inline-end: calc(16px + 20.3vw);
  }

  @media screen and (width >= 1500px) {
    padding-inline: calc(50vw - 362px) 5vw;
    margin-inline-end: calc(50vw - 355px - 5vw);
  }
`;

export const BigTitle = styled(H1)`
  margin: 0;
  font-size: 3rem;
  font-weight: ${fontWeights.normal};
  margin-block-end: ${spacing.tiny};
  margin-inline-end: 16px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${fontSizes.headline};
    margin-block-end: 40px;
  }
`;

export const BigDescription = styled.p`
  font-size: ${fontSizes.header4};
  line-height: 26px;
  font-weight: ${fontWeights.normal};
  margin-block: 0 20px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    letter-spacing: 0.5px;
    margin-block-end: 0;
  }
`;

type BoxSmallProps = Pick<HeroBlockStyledProps, '$boxColor'>;

export const ContentBoxSmall = styled.div<BoxSmallProps>`
  position: relative;
  align-self: flex-start;
  width: 85%;
  max-width: 65ch;
  min-height: 200px;
  bottom: 0;
  margin-block-start: -5rem;
  box-sizing: border-box;
  display: flex;
  padding: 3rem 1.5rem;
  background-color: ${({ $boxColor }) => $boxColor};
  align-items: center;
  line-height: 24px;
  z-index: 1;

  p {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inline-size: 600px;
    padding: 5rem;
    min-block-size: 256px;
    margin-block-start: -7rem;
  }

  @media screen and (width >= 1480px) {
    margin-inline-start: calc(50vw - 720px - 5rem);
  }
`;
